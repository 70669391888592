import Vue from "vue";
import Router from "vue-router";
import jwt from "jsonwebtoken";

import Register from "@/containers/Register/Register";
import RegisterSuccess from "@/containers/Register/RegisterSuccess";
import Login from "@/containers/Login/Login";
import ChangePassword from "@/containers/ChangePassword/ChangePassword";
import User from "@/containers/User/User";

function beforeEnter(to, from, next) {
  const token = window.localStorage.getItem("token");
  if (!token) {
    Vue.prototype.$Notify({
      type: "danger",
      message: Vue.prototype.$ct("请先登录"),
    });
    next("/login");
    return;
  }
  const tokenData = jwt.decode(token);
  console.log(tokenData);
  if (tokenData.exp < Date.now() / 1000) {
    Vue.prototype.$Notify({
      type: "danger",
      message: Vue.prototype.$ct("登录超时，请重新登录"),
    });
    window.localStorage.clear();
    next("/login");
    return;
  }
  next();
}

Vue.use(Router);

const routes = new Router({
  mode: "history",
  routes: [
    {
      path: "/",
      redirect: "/login",
    },
    {
      path: "/register",
      component: Register,
      name: "Register",
    },
    {
      path: "/registerSuccess",
      component: RegisterSuccess,
      name: "RegisterSuccess",
    },
    {
      path: "/login",
      component: Login,
      name: "Login",
    },
    {
      path: "/changepassword",
      component: ChangePassword,
      name: "ChangePassword",
    },
    {
      path: "/user",
      component: User,
      name: "User",
      beforeEnter,
    },

    {
      path: "/*",
      name: "404",
      redirect: "/",
    },
  ],
});

export default routes;
